import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import { ReactComponent as PhoneIcons } from "../../../assets/images/icons/Phone.svg";
import { ReactComponent as LocationIcon } from "../../../assets/images/icons/location.svg";
import { ReactComponent as EmailIcons } from "../../../assets/images/icons/email.svg";


const Footer = () => {
  return (
    <Box
      sx={{
        mb: {
          xs: "0px",
          sm: "0px",
          md: "60px",
          lg: "60px",
        },
        mt: "150px",
      }}
    >
      <footer>
        <Box sx={{ width: "70%", margin: "0 auto" }}>
          <Box>
            <Typography
              sx={{
                color: "var(--mateblack)",
                fontSize: {
                  xs: "20px",
                  sm: "20px",
                  md: "40px",
                  lg: "50px",
                },
              }}
            >
              discuss a project?
            </Typography>
            <Link to="/contacts" style={{textDecoration:"none",cursor:"pointer"}}>
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontSize: {
                  xs: "20px",
                  sm: "20px",
                  md: "35px",
                  lg: "50px",
                },
                ":hover":{
                  textDecoration:"underline"
                }
              }}
            >
              tell us about it
            </Typography>
            </Link>
           
          </Box>

          <Box sx={{ pt: "50px" }}>
            <Grid container spacing={3}>
              <Grid item md={3.5} lg={4} sm={6} xs={12}>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: "var(--primary-color)",
                        fontWeight: "500",
                      }}
                    >
                      Contacts
                    </Typography>
                    <Typography
                      sx={{
                  
                        pt: "12px",
                        color: "var(--mateblack)",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <PhoneIcons /> <Typography sx={{fontSize:"14px", color: "#29292B",}}>+880 138414454145</Typography>
                    </Typography>
                    <Typography
                      sx={{
                        pt: "10px",
                        color: "var(--mateblack)",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <EmailIcons />{" "}
                      <Typography sx={{fontSize:"14px", color: "#29292B",}}>
                        info@flyfartech
                      </Typography>
                    </Typography>
                    <Typography
                      sx={{
                     
                        pt: "10px",
                        color: "var(--mateblack)",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        gap:"8px"
          
                      }}
                    >
                      <LocationIcon />{" "}
                      <Typography
                        sx={{fontSize:"14px", color: "#29292B",width:"80%"}}
                      >
                        Ka 11/2A, Bashundhora R/A Road, Jagannathpur, Dhaka
                        1229.
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={2} lg={3} sm={6} xs={12}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "var(--primary-color)",
                      fontWeight: "500",
                    }}
                  >
                    Product
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      pt: "12px",
                      color: "#29292B",
                      fontWeight: "500",
                    }}
                  >
                    Web Applications
                  </Typography>
                  <Typography
                   sx={{
                    fontSize: "14px",
                    pt: "10px",
                    color: "#29292B",
                    fontWeight: "500",
                  }}
                  >
                    ERP Software
                  </Typography>
                  <Typography
                   sx={{
                    fontSize: "14px",
                    pt: "10px",
                    color: "#29292B",
                    fontWeight: "500",
                  }}
                  >
                    Mobile Applications
                  </Typography>
                  <Typography
                  sx={{
                    fontSize: "14px",
                    pt: "10px",
                    color: "#29292B",
                    fontWeight: "500",
                  }}
                  >
                    Travel API Solutions
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      pt: "10px",
                      color: "#29292B",
                      fontWeight: "500",
                    }}
                  >
                    Maqnagement Softwares
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={3} lg={2.5} sm={6} xs={12}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "var(--primary-color)",
                      fontWeight: "500",
                    }}
                  >
                    Resources
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      pt: "12px",
                      color: "#29292B",
                      fontWeight: "500",
                    }}
                  >
                    Blogs
                  </Typography>
                  <Typography
                  sx={{
                    fontSize: "14px",
                    pt: "10px",
                    color: "#29292B",
                    fontWeight: "500",
                  }}
                  >
                    Testimonials
                  </Typography>
                  <Typography
                   sx={{
                    fontSize: "14px",
                    pt: "10px",
                    color: "#29292B",
                    fontWeight: "500",
                  }}
                  >
                    Complete Products
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={3} lg={2.5} sm={6} xs={12}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "var(--primary-color)",
                      fontWeight: "500",
                    }}
                  >
                    Company
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      pt: "12px",
                      color: "#29292B",
                      fontWeight: "500",
                      
                    }}
                  >
                    Who We Are
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      pt: "10px",
                      color: "#29292B",
                      fontWeight: "500",
                    }}
                  >
                    Leadership Team
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      pt: "10px",
                      color: "#29292B",
                      fontWeight: "500",
                    }}
                  >
                    Careers
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      pt: "10px",
                      color: "#29292B",
                      fontWeight: "500",
                    }}
                  >
                    Contact Us
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "start",
                sm: "start",
                md: "start",
                lg: "space-between",
              },
              alignItems: {
                xs: "start",
                sm: "start",
                md: "center",
                lg: "center",
              },
              py: "50px",
              borderBottom: "1px solid var(--secondary-color)",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },
            }}
          ></Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              },

              py: "10px",
            }}
          >
            <Typography sx={{ color: "var(--mateblack)", fontSize: "18px" }}>
              © All rights reserved by FLY FAR{" "}
              <span style={{ color: "var(--secondary-color)" }}>TECH</span>{" "}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: {
                  xs: "10px",
                  sm: "10px",
                  md: "30px",
                  lg: "30px",
                },
                color: "var(--mateblack)",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
              }}
            >
              <Typography>Privacy Policy</Typography>
              <Typography>Terms of use</Typography>
              <Box
                sx={{
                  display: "flex",
                  color: "var(--secondary-color)",
                  gap: "8px",
                  /* change will be here */
                  "@media only screen and (max-width: 600px)": {
                    fontSize: "20px",
                  },
                }}
              >
                <Link
                  to="https://www.facebook.com/flyfartech"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                >
                  <FacebookIcon
                    sx={{
                      listStyle: "none",
                      color: "var(--secondary-color)",
                      fontSize: "30px",
                      /* change will be here */
                      "@media only screen and (max-width: 600px)": {
                        fontSize: "20px",
                      },
                    }}
                  />
                </Link>
                <GitHubIcon
                  sx={{
                    listStyle: "none",
                    color: "var(--secondary-color)",
                    fontSize: "30px",
                    /* change will be here */
                    "@media only screen and (max-width: 600px)": {
                      fontSize: "20px",
                    },
                  }}
                />
                <LinkedInIcon
                  sx={{
                    listStyle: "none",
                    color: "var(--secondary-color)",
                    fontSize: "30px",
                    /* change will be here */
                    "@media only screen and (max-width: 600px)": {
                      fontSize: "20px",
                    },
                  }}
                />
                <WhatsAppIcon
                  sx={{
                    listStyle: "none",
                    color: "var(--secondary-color)",
                    fontSize: "30px",
                    /* change will be here */
                    "@media only screen and (max-width: 600px)": {
                      fontSize: "20px",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </footer>
    </Box>
  );
};

export default Footer;
