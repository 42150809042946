export default function getCursorProps(bannerElements, mousePosition) {
  if (Object.keys(bannerElements).length !== 0 && mousePosition) {
    let design = bannerElements.design.current.getBoundingClientRect();
    let code = bannerElements.code.current.getBoundingClientRect();
    let build = bannerElements.build.current.getBoundingClientRect();
    let deploy = bannerElements.deploy.current.getBoundingClientRect();
    console.log(deploy);

    let scrollY = window.pageYOffset;

    let design_xmin = design.x;
    let design_xmax = design.x + design.width;
    let design_ymin = design.y + scrollY;
    let design_ymax = design.y + design.height + scrollY;

    // let code_xmin = code.x;
    let code_xmax = code.x + code.width;
    // let code_ymin = code.y;
    // let code_ymax = code.y + code.height;

    // let build_xmin = build.x;
    let build_xmax = build.x + build.width;
    // let build_ymin = build.y;
    // let build_ymax = build.y + build.height;
    let deploy_xmax = deploy.x + deploy.width;

    let mouse_x = mousePosition.x;
    let mouse_y = mousePosition.y;

    let cursorprops = {
      videoID: null,
      scale: 1,
    };
    if (
      design_xmin < mouse_x &&
      mouse_x < deploy_xmax &&
      design_ymin - 300 < mouse_y &&
      mouse_y < design_ymax + 300
    ) {
      if (mouse_x < design_xmax) {
        cursorprops.videoID = "design";
      } else if (mouse_x < code_xmax) {
        cursorprops.videoID = "code";
      } else if (mouse_x < build_xmax) {
        cursorprops.videoID = "build";
      } else if (mouse_x < deploy_xmax) {
        cursorprops.videoID = "deploy";
      }

      if (mouse_y > design_ymin && mouse_y < design_ymax) {
        cursorprops.scale = 38;
      } else {
        cursorprops.scale = 12;
      }
    }
    return cursorprops;
  }
}
