import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
// import Home from "./components/Home/Home";
import MainProjects from "./components/MainProjects/MainProjects";
import MainServices from "./components/MainServices/MainServices";
import MainService from "./components/MainService/MainService";
import Company from "./components/Company/Company";
import Blogs from "./components/Blogs/Blogs";
import Teams from "./components/Teams/Teams";
import Contacts from "./components/Contacts/Contacts";
import Testimonials from "./components/Testimonials/Testimonials";
import ScrollToTop from "./components/ScrollToTop";
import { Rating } from "@mui/material";
import MainBlog from "./components/MainBlog/MainBlog";
import MainProject from "./components/MainProject/MainProject";
import Career from "./components/Career/Career";
import CareerOption from "./components/CareerOption/CareerOption";
import DropCv from "./components/DropCV/DropCv";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import Home from "./Pages/Version2/Home/Home";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contacts" element={<Contacts />} />
      
      {/*
      <Route path="/projects" element={<MainProjects />} />
        <Route path="projects/project" element={<MainProject />} />
        <Route path="/services" element={<MainServices />} />
        <Route path="/services/service" element={<MainService />} />
        <Route path="/company" element={<Company />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/blog" element={<MainBlog />} />
        <Route path="/career" element={<Career />} />
        <Route path="/careeroption" element={<CareerOption />} />
        <Route path="/dropcv" element={<DropCv />} />
        <Route path="*" element={<ErrorPage />} />
      
      
      
      
      */}
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
