import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Product from "./Product";
import { Fade } from "react-reveal";

const Products = () => {
  const spanStyle = {
    cursor: "pointer",
    color: "var(--primary-color)",
  };
  const [selectedItem, setSelectedItem] = useState(0);
  const handleClick = (index) => {
    console.log("click", index);
    setSelectedItem(index);
  };

  return (
    <Box>
      <Box sx={{ width: "70%", margin: "50px auto" }}>
        {/* productes title */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            mt: "330px",
          }}
        >
          <Typography sx={{ fontSize: "45px", fontWeight: "500" }}>
            our best <span style={spanStyle}>product</span>
          </Typography>
          <Box>
            <Box
              sx={{
                bgcolor: "var(--primary-color)",
                p: "12px 25px 14px 25px ",
                color: "var(--white)",
                borderRadius: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              View all Product
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: "50px" }}>
          <Box style={{ display: "flex", gap: "13px" }}>
            <Box
              className={
                selectedItem === 0 ? "product-list" : "un-product-list"
              }
              onClick={() => handleClick(0)}
            >
              Whitelable OTA Portal
            </Box>
            <Box
              className={
                selectedItem === 1 ? "product-list" : "un-product-list"
              }
              onClick={() => handleClick(1)}
            >
             Flight API
            </Box>
            <Box
              className={
                selectedItem === 2 ? "product-list" : "un-product-list"
              }
              onClick={() => handleClick(2)}
            >
              HRM Pro
            </Box>
          </Box>

          <Product selectedItem={selectedItem} />
        </Box>
      </Box>
    </Box>
  );
};

export default Products;
