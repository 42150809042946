import { WhatsApp } from "@mui/icons-material";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";
import bg from "../../../../assets/images/bg4.svg";
import { TypeAnimation } from "react-type-animation";
import { ReactComponent as BgIcon } from "../../../../assets/images/bg4.svg";

const Banner = () => {
  const [isHovered, setHovered] = useState(false);
  const spanStyle = {
    cursor: "pointer",
    color: "var(--primary-color)",
  };

  return (
    <Box
      sx={{
        position: "relative",
        bgcolor: "re",
        width: "100vw",
       
      }}
    >
      <Box
        sx={{
          width: "70%",
          margin: "50px auto",
        }}
      >
        <Grid>
          <Grid item md={12} xs={12}>
            <Box sx={{ width: "100%", pt: "50px" }}>
              <Typography
                sx={{ color: "var(--primary-color)", fontSize: "30px" }}
              >
                Where vision meets execution
              </Typography>
              <Typography
                sx={{
                  color: "var(--mateblack)",
                  fontSize: {
                    xs: "40px",
                    sm: "40px",
                    md: "80px",
                    lg: "70px",
                  },
                  whiteSpace: "nowrap",
                  pt: "20px",
                  cursor: "pointer",
                }}
              >
                Asia Leading{" "}
                <span style={spanStyle}>
                  {" "}
                  <TypeAnimation
                    sequence={[
                      "ERP Management",
                      2000,
                      "OTA Management",
                      2000,
                      "HRM Management",
                      2000,
                      () => {
                        console.log("Sequence completed");
                      },
                    ]}
                    wrapper="span"
                    cursor={true}
                    repeat={Infinity}
                    style={{ fontSize: "70px", display: "inline-block" }}
                  />
                </span>{" "}
                <br /> <span style={spanStyle}>Technology</span> Expert.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: {
                    xs: "start",
                    sm: "start",
                    md: "center",
                    lg: "center",
                  },

                  gap: {
                    xs: "20px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                  },
                  pt: {
                    xs: "10px",
                    sm: "10px",
                    md: "40px",
                    lg: "80px",
                  },

                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                  },
                }}
              >
                <Box
                  sx={{
                    bgcolor: "var(--primary-color)",
                    p: "12px 20px 15px 20px ",
                    color: "var(--white)",
                    borderRadius: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  get your own qoute!
                </Box>
                <Box
                  sx={{
                    border: "2px solid var(--secondary-color)",
                    p: "12px 20px 15px 20px ",
                    color: "var(--mateblack)",
                    borderRadius: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  wants to know more ?
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ top: "22%", position: "fixed", right: "0px", bottom: "0px" }}>
        <Box
          sx={{
            color: "var(--white)",
            bgcolor: "var(--primary-color)",
            width: "60px",
            height: "70px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WhatsAppIcon sx={{ color: "var(white)" }} />
        </Box>
        <Link
          to="https://www.facebook.com/flyfartech"
          target="_blank"
          style={{ cursor: "pointer" }}
        >
          <Box
            sx={{
              mt: "5px",
              color: "var(--white)",
              bgcolor: "var(--primary-color)",
              width: "60px",
              height: "70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FacebookIcon sx={{ color: "var(white)" }} />
          </Box>
        </Link>

        <Link
          to="mailto:support@flyfar.tech"
          target="_blank"
          style={{ cursor: "pointer" }}
        >
          <Box
            sx={{
              mt: "5px",
              color: "var(--white)",
              bgcolor: "var(--primary-color)",
              width: "60px",
              height: "70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MailOutlineIcon sx={{ color: "var(white)" }} />
          </Box>
        </Link>
        <Link
          to="https://bd.linkedin.com/company/fly-far-tech"
          target="_blank"
          style={{ cursor: "pointer" }}
        >
          <Box
            sx={{
              mt: "5px",
              color: "var(--white)",
              bgcolor: "var(--primary-color)",
              width: "60px",
              height: "70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LinkedInIcon sx={{ color: "var(white)" }} />
          </Box>
        </Link>
      </Box>

      {/* <BgIcon
        style={{
          width: "100%",
          height: "200vh",
          position: "absolute",
          top: "-35%",
          left: "-35%",
        }}
      /> */}
    </Box>
  );
};

export default Banner;
