import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

const ChooseUs = () => {
  return (
    <Box sx={{ mt: "200px" }}>
      <Box sx={{ width: "70%", m: "40px auto" }}>
        <Box sx={{ textAlign: "left" }}>
          <Typography
            sx={{
              color: "var(--mateblack)",
              fontWeight: "400",
              fontSize: "50px",
            }}
          >
            reasones
          </Typography>
          <Typography
            sx={{
              color: "var(--primary-color)",
              fontWeight: "400",
              fontSize: {
                xs: "30px",
                sm: "30px",
                md: "40px",
                lg: "50px",
              },
            }}
          >
            why choose us
          </Typography>
          {/* <Typography
            sx={{ color: "var(--card)", py: "10px", textAlign: "center" }}
          >
            It is a long established fact that a reader will be distracted by
            the readable <br /> content of a page when looking at its layout.
          </Typography> */}
        </Box>
        <Box>
          <Grid container sx={{ py: "80px" }} spacing={5}>
            <Grid item md={4}>
              <Box
                sx={{
                  bgcolor: "#FFFFFF",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  px: "15px",
                  position: "relative",
                  fontWeight: "600",
                  color: "var(--primary-color)",
                  fontSize: "16px",
                  borderRadius: "5px",
                  transition: "background-color 1s",
                  "&:hover": {
                    bgcolor: "var(--primary-color)",
                    color: "white",
                    "& > p": {
                      color: "white",
                    },
                  },
                }}
              >
                Travel domain is our specialization
                <Typography
                  sx={{
                    fontSize: "13px",
                    mt: "1",
                    color: "black",
                    "&:hover": {
                      color: "white",
                    },
                  }}
                >
                  {" "}
                  Specializing in travel, we offer tailored solutions for
                  seamless booking and itinerary management.
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#EEEEEE",
                    height: "50px",
                    width: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "-15%",
                    borderRadius: "5px",
                    left: "8%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: "20px",
                      ":hover": {
                        color: "var(--primary-color)",
                      },
                    }}
                  >
                    {" "}
                    0 1
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{
                  bgcolor: "#FFFFFF",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  px: "15px",
                  position: "relative",
                  fontWeight: "600",
                  color: "var(--primary-color)",
                  fontSize: "16px",
                  borderRadius: "5px",
                  transition: "background-color 1s",
                  "&:hover": {
                    bgcolor: "var(--primary-color)",
                    color: "white",
                    "& > p": {
                      color: "white",
                    },
                  },
                }}
              >
                <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                  {" "}
                  More than 100+ website developed
                </Typography>
                <Typography sx={{ fontSize: "13px", mt: "1", color: "black" }}>
                  {" "}
                  We've developed over 100 websites, each tailored to
                  perfection."
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#EEEEEE",
                    height: "50px",
                    width: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "-15%",
                    left: "8%",
                    borderRadius: "5px",
                    ":hover": {
                      ":hover": {
                        color: "var(--primary-color)",
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: "20px",
                      ":hover": {
                        color: "var(--primary-color)",
                      },
                    }}
                  >
                    {" "}
                    0 2
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{
                  bgcolor: "#FFFFFF",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  px: "15px",
                  position: "relative",
                  fontWeight: "600",
                  color: "var(--primary-color)",
                  fontSize: "16px",
                  borderRadius: "5px",
                  transition: "background-color 1s",
                  "&:hover": {
                    bgcolor: "var(--primary-color)",
                    color: "white",
                    "& > p": {
                      color: "white",
                    },
                  },
                }}
              >
                <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                  {" "}
                  24/7 Fast support with experts
                </Typography>
                <Typography sx={{ fontSize: "13px", mt: "1", color: "black" }}>
                  {" "}
                  Round-the-clock expert support for all your needs.
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#EEEEEE",
                    height: "50px",
                    width: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "-15%",
                    borderRadius: "5px",
                    left: "8%",
                    ":hover": {
                      ":hover": {
                        color: "var(--primary-color)",
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: "20px",
                      ":hover": {
                        color: "var(--primary-color)",
                      },
                    }}
                  >
                    {" "}
                    0 3
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{
                  bgcolor: "#FFFFFF",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  px: "15px",
                  position: "relative",
                  fontWeight: "600",
                  color: "var(--primary-color)",
                  fontSize: "16px",
                  borderRadius: "5px",
                  transition: "background-color 1s",
                  "&:hover": {
                    bgcolor: "var(--primary-color)",
                    color: "white",
                    "& > p": {
                      color: "white",
                    },
                  },
                }}
              >
                <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                  {" "}
                  6+ Years of experience of this industry
                </Typography>
                <Typography sx={{ fontSize: "13px", mt: "1", color: "black" }}>
                  {" "}
                  With over 6 years of industry expertise, we deliver
                  excellence.
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#EEEEEE",
                    height: "50px",
                    width: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "-15%",
                    borderRadius: "5px",
                    left: "8%",
                    ":hover": {
                      ":hover": {
                        color: "var(--primary-color)",
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: "20px",
                      ":hover": {
                        color: "var(--primary-color)",
                      },
                    }}
                  >
                    {" "}
                    0 4
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{
                  bgcolor: "#FFFFFF",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  px: "15px",
                  position: "relative",
                  fontWeight: "600",
                  color: "var(--primary-color)",
                  fontSize: "16px",
                  borderRadius: "5px",
                  transition: "background-color 1s",
                  "&:hover": {
                    bgcolor: "var(--primary-color)",
                    color: "white",
                    "& > p": {
                      color: "white",
                    },
                  },
                }}
              >
                <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                  {" "}
                  User experience
                </Typography>
                <Typography sx={{ fontSize: "13px", mt: "1", color: "black" }}>
                  {" "}
                  Enhance user experience with our intuitive solutions.
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#EEEEEE",
                    height: "50px",
                    width: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "-15%",
                    borderRadius: "5px",
                    left: "8%",
                    ":hover": {
                      ":hover": {
                        color: "var(--primary-color)",
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: "20px",
                      ":hover": {
                        color: "var(--primary-color)",
                      },
                    }}
                  >
                    {" "}
                    0 5
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{
                  bgcolor: "#FFFFFF",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  height: "150px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  px: "15px",
                  position: "relative",
                  fontWeight: "600",
                  color: "var(--primary-color)",
                  fontSize: "16px",
                  borderRadius: "5px",
                  transition: "background-color 1s",
                  "&:hover": {
                    bgcolor: "var(--primary-color)",
                    color: "white",
                    "& > p": {
                      color: "white",
                    },
                  },
                }}
              >
                <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                  {" "}
                  Most Advanced Features
                </Typography>
                <Typography sx={{ fontSize: "13px", mt: "1", color: "black" }}>
                  {" "}
                  Experience the pinnacle of innovation with our cutting-edge
                  features.
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#EEEEEE",
                    height: "50px",
                    width: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "-15%",
                    borderRadius: "5px",
                    left: "8%",
                    ":hover": {
                      ":hover": {
                        color: "var(--primary-color)",
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      color: "black",
                      fontSize: "20px",
                      ":hover": {
                        color: "var(--primary-color)",
                      },
                    }}
                  >
                    {" "}
                    0 6
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseUs;
