import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import { Box, Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Fade } from "react-reveal";
import Swal from "sweetalert2";
import Footer from "../../Pages/Version2/Footer/Footer";
import { Link } from "react-router-dom";

const Contacts = () => {
  let Cat;
  const [value, setValue] = useState(1);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleValue = (val) => {
    console.log(val);
    setValue(val);
  };

  const [mode, setMode] = useState(() => {
    const storedMode = localStorage.getItem("mode");
    return storedMode === "true";
  });

  const handleMode = () => {
    setMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("mode", newMode.toString());
      return newMode;
    });
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--body-background-color",
      mode ? "white" : "#222222"
    );
  }, [mode]);

  // posting to the contact

  const [formInfo, setFormInfo] = useState({});

  const handleChange = (e) => {
    if (e.target.name === "Attachment") {
      setFormInfo((prevData) => ({
        ...prevData,
        Attachment: e.target.files[0],
      }));
    } else {
      setFormInfo((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let Cat;

    if (value === 1) {
      Cat = "App Development";
    } else if (value === 2) {
      Cat = "Web Development";
    } else if (value === 3) {
      Cat = "OTA Solutions";
    } else {
      Cat = "API Solutions";
    }

    const formData = new FormData();
    formData.append("Name", formInfo.Name);
    formData.append("Description", formInfo.Description);
    formData.append("Attachment", formInfo.Attachment);
    formData.append("Email", formInfo.Email);
    formData.append("Category", Cat);

    console.log("Form Data:", Object.fromEntries(formData));

    fetch("https://fly-far-tech.as.r.appspot.com/contact/create", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        Swal.fire({
          icon: "success",
          text: "Email Sent Successfully!",
        }).then(() => {
          setFormInfo({});
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire({
          icon: "error",
          title: error.message,
          text: "Failed to send email.",
        });
      });
  };

  return (
    <>
      <SideBar
        open={open}
        setOpen={setOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleMode={handleMode}
        mode={mode}
      />
      <Box
        sx={{ width: "70%", margin: "0 auto", pb: "120px" }}
        onClick={handleDrawerClose}
      >
        <Fade bottom>
          {" "}
          <Box sx={{ pt: "90px" }}>
            <Typography sx={{ fontSize: "20px", pb: "10px" }}>
              <Link style={{textDecoration:"none"}} to="/"><span style={{ color: "#666676" }}>home/ </span></Link>
              <span style={{ color: "var(--secondary-color)" }}>contacts</span>
            </Typography>
            <span
              style={{
                color: "var(--mateblack)",
                fontSize: "50px",
              }}
            >
              hey! tell us about
            </span>
            <br></br>
            <span
              style={{
                color: "var(--primary-color)",
              }}
              className="service-text"
            >
              your project
            </span>
          </Box>
        </Fade>

        <Box sx={{ paddingTop: "60px" }}>
          <Fade bottom>
            <Typography sx={{ color: "var(--footer-color)" }}>
              I’m interested in....
            </Typography>
          </Fade>

          <Fade bottom>
            {" "}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                paddingTop: "20px",
                "@media (max-width: 768px)": {
                  flexDirection: "column",
                },
              }}
            >
              <Button
                onClick={() => handleValue(1)}
                sx={{
                  "@media (max-width: 768px)": {
                    fontSize: "14px",
                  },
                  bgcolor:
                    value === 1
                      ? "var(--primary-color)"
                      : value === 1
                      ? "var(--primary-color)"
                      : "transparent",
                  color: value === 1 ? "var(--white)" : "var(--mateblack)",

                  textTransform: "none",
                  borderRadius: "50px",
                  width: "180px",
                  transition: "0.2s",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  border:
                    value === 1
                      ? "2px solid var(--primary-color)"
                      : "1px solid var(--primary-color)",

                  ":hover": {
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white)",
                    border: "1px solid var(--primary-color)",
                  },
                }}
              >
                App Development
              </Button>

              <Button
                onClick={() => handleValue(2)}
                sx={{
                  "@media (max-width: 768px)": {
                    fontSize: "14px",
                  },
                  bgcolor:
                    value === 2
                      ? "var(--primary-color)"
                      : value === 2
                      ? "var(--primary-color)"
                      : "transparent",
                  color: value === 2 ? "var(--white)" : "var(--mateblack)",

                  textTransform: "none",
                  borderRadius: "50px",
                  width: "180px",
                  transition: "0.2s",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  border:
                    value === 2
                      ? "2px solid var(--primary-color)"
                      : "1px solid var(--primary-color)",

                  ":hover": {
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white)",
                    border: "1px solid var(--primary-color)",
                  },
                }}
              >
                Web Development
              </Button>
              <Button
                onClick={() => handleValue(3)}
                sx={{
                  bgcolor:
                    value === 3 ? "var(--primary-color)" : "transparent",
                  color: value === 3 ? "var(--white)" : "var(--mateblack)",
                  // bgcolor:
                  //   value === 3 && mode
                  //     ? "var(--primary-color)"
                  //     : value === 3
                  //     ? "var(--primary-color)"
                  //     : "transparent",
                  // color:
                  //   value === 3 && mode
                  //     ? "var(--white)"
                  //     : value === 3
                  //     ? "var(--white)"
                  //     : mode
                  //     ? "var(--mateblack)"
                  //     : "var(--white)",

                  // color: mode ? "var(--mateblack)" : "var(--white)",
                  textTransform: "none",
                  borderRadius: "50px",
                  width: "180px",
                  transition: "0.2s",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  border:
                    value === 3 && mode
                      ? ""
                      : value === 3
                      ? ""
                      : mode
                      ? "2px solid var(--primary-color)"
                      : "1px solid var(--primary-color)",
                  ":hover": {
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white)",
                    border: "1px solid var(--primary-color)",
                  },
                }}
              >
                OTA Solutions
              </Button>
              <Button
                onClick={() => handleValue(4)}
                sx={{
                  bgcolor:
                    value === 4 ? "var(--primary-color)" : "transparent",
                  color: value === 4 ? "var(--white)" : "var(--mateblack)",
                  // bgcolor:
                  //   value === 3 && mode
                  //     ? "var(--primary-color)"
                  //     : value === 3
                  //     ? "var(--primary-color)"
                  //     : "transparent",
                  // color:
                  //   value === 3 && mode
                  //     ? "var(--white)"
                  //     : value === 3
                  //     ? "var(--white)"
                  //     : mode
                  //     ? "var(--mateblack)"
                  //     : "var(--white)",

                  // color: mode ? "var(--mateblack)" : "var(--white)",
                  textTransform: "none",
                  borderRadius: "50px",
                  width: "180px",
                  transition: "0.2s",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  border:
                    value === 4 && mode
                      ? ""
                      : value === 4
                      ? ""
                      : mode
                      ? "2px solid var(--primary-color)"
                      : "1px solid var(--primary-color)",
                  ":hover": {
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white)",
                    border: "1px solid var(--primary-color)",
                  },
                }}
              >
                API Solutions
              </Button>
            </Box>
          </Fade>

          <Fade bottom>
            <form onSubmit={handleSubmit} sx={{ pt: "50px" }}>
              <Box sx={{ pt: "80px" }}>
                <TextField
                  fullWidth
                  type="text"
                  label="your name"
                  name="Name"
                  onChange={handleChange}
                  InputProps={{
                    // startAdornment: (
                    //   <InputAdornment position="start"></InputAdornment>
                    // ),
                    sx: {
                      color: "var(--light-bg)",
                      pt: "5px",
                    },
                  }}
                  InputLabelProps={{
                    sx: { color: "var(--footer-color)", fontSize: "20px" },
                  }}
                  variant="standard"
                  sx={{
                    ".MuiInput-root:before": {
                      borderBottomColor: "var(--footer-color)",
                      pb: "10px",
                    },
                  }}
                />
              </Box>
              <Box sx={{ pt: "60px" }}>
                <TextField
                  fullWidth
                  type="email"
                  label="your email"
                  name="Email"
                  onChange={handleChange}
                  InputProps={{
                    // startAdornment: (
                    //   <InputAdornment position="start"></InputAdornment>
                    // ),
                    sx: {
                      color: "var(--light-bg)",
                      pt: "5px",
                    }, // Set the label color and font size
                  }}
                  InputLabelProps={{
                    // Set the label color and font size
                    sx: { color: "var(--footer-color)", fontSize: "20px" },
                  }}
                  variant="standard"
                  sx={{
                    ".MuiInput-root:before": {
                      borderBottomColor: "var(--footer-color)",
                    },
                  }}
                />
              </Box>
              <Box sx={{ pt: "60px" }}>
                <TextField
                  name="Description"
                  onChange={handleChange}
                  type="text"
                  fullWidth
                  label="tell us about your project"
                  InputProps={{
                    // startAdornment: (
                    //   <InputAdornment position="start"></InputAdornment>
                    // ),
                    sx: {
                      color: "var(--light-bg)",
                      fontSize: "15px",
                      pt: "5px",
                    },
                  }}
                  InputLabelProps={{
                    sx: { color: "var(--footer-color)", fontSize: "20px" },
                  }}
                  variant="standard"
                  sx={{
                    ".MuiInput-root:before": {
                      borderBottomColor: "var(--footer-color)",
                    },
                  }}
                />
              </Box>
              <Box>
                <input
                  type="file"
                  name="Attachment"
                  required
                  onChange={handleChange}
                  accept="image/jpeg, image/png, application/pdf"
                  style={{ display: "none" }}
                  id="file-input"
                />

                <label htmlFor="file-input">
                  <Button
                    variant="outlined"
                    component="span"
                    sx={{
                      color: "var(--mateblack)",
                      textTransform: "none",
                      mt: "50px",
                      border: "none",
                      pl: "0px",
                      fontSize: "18px",
                      ":hover": { border: "none" },
                    }}
                  >
                    <AttachFileIcon sx={{ transform: "rotate(45deg)" }} />
                    <span>Add Attachment</span>
                  </Button>
                </label>
              </Box>

              <Button
                type="submit"
                sx={{
                  textTransform: "none",
                  fontSize: "18px",
                  borderBottom: "1px solid",
                  borderRadius: "0px",
                  color: "var(--footer-color)",
                  mt: "80px",
                }}
              >
                Send Request
              </Button>
            </form>
          </Fade>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default Contacts;
