import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import logo from "../../assets/images/logo.png";
import daylogo from "../../assets/images/daylogo.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Link } from "react-router-dom";
import menuIcons from "../../assets/images/MENU.svg";

const drawerWidth = "50%";
const smallDrawerWidth = "100%";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 70,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(30px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark" ? "#8796A5" : "var(--tertiary-color)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor:
      theme.palette.mode === "dark" ? "var(--tertiary-color)" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "var(--tertiary-color)"
        : "var(--tertiary-color)",
    borderRadius: 20 / 2,
  },
}));

const SideBar = ({
  open,
  setOpen,
  handleDrawerOpen,
  handleDrawerClose,
  handleMode,
  mode,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText("Info@flyfartech.com");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const theme = useTheme();

  return (
    <Box>
      <Box
        open={open}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "70%",
          height: "10vh",
          margin: "0 auto",
          position: "relative",
          pt: "35px",
        }}
      >
        <Box>
          <Link to="/">
            <Box>
              <img
                style={{ height: "38px", width: "116px" }}
                src={daylogo}
                alt="Logo"
              />
            </Box>
          </Link>
        </Box>

        <Box sx={{ cursor: "pointer" }} onClick={handleDrawerOpen}>
          <img style={{ height: "20px" }} src={menuIcons} />
        </Box>
      </Box>
      <Drawer
        sx={{
          flexShrink: 0,
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
          "& .MuiDrawer-paper": {
            width: "50%",
            boxSizing: "border-box",
            backgroundColor: "var(--secondary-color)",
          },
          "@media only screen and (max-width: 768px)": {
            "& .MuiDrawer-paper": {
              width: "100%",
            },
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader
          sx={{
            paddingLeft: "70px",
            display: "flex",
            justifyContent: "space-between",
            "@media only screen and (max-width: 600px)": {
              paddingLeft: "20px",
            },
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronRightIcon
                sx={{
                  backgroundColor: "var(--mateblack)",
                  color: "var(--secondary-color)",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
          {/* <FormControlLabel
            sx={{
              paddingRight: "30px",
              "@media only screen and (max-width: 600px)": {
                paddingRight: "0px",
              },
            }}
            control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
            onClick={handleMode}
          /> */}
        </DrawerHeader>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "30px",
            width: "80%",
            margin: "0 auto",
            "@media only screen and (max-width: 600px)": {
              width: "85%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexBasis: "30%",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "var(--tertiary-color)",
                  paddingBottom: "40px",
                  fontSize: "20px",
                }}
              >
                Social
              </Typography>
              <Box>
                <ul className="social-lists">
                  <Link
                    to="https://www.facebook.com/flyfartech"
                    target="_blank"
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <li className="menu-items">Facebook</li>
                  </Link>

                  <Link
                    to="https://www.linkedin.com/company/fly-far-tech/mycompany/"
                    target="_blank"
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <li className="menu-items">Instagram</li>
                  </Link>

                  <Link
                    to="https://www.linkedin.com/company/fly-far-tech/mycompany/"
                    target="_blank"
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <li className="menu-items">LinkedIn</li>
                  </Link>

                  <li className="menu-items">Whatsapp</li>
                  <li className="menu-items">Behance</li>
                </ul>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexBasis: "30%",
              }}
            >
              <Box sx={{}}>
                <Typography
                  sx={{
                    color: "var(--tertiary-color)",
                    paddingBottom: "30px",
                    fontSize: "20px",
                  }}
                >
                  Menu
                </Typography>
                <Box
                  sx={{
                    paddingRight: "20px",
                    "@media only screen and (max-width: 600px)": {
                      paddingRight: "0px",
                    },
                  }}
                >
                  <ul className="menu-lists">
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <li className="menu-items">Home</li>
                    </Link>

                    <Link to="/projects" style={{ textDecoration: "none" }}>
                      <li
                        className="menu-items"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        Projects
                      </li>
                    </Link>
                    <Link to="/services" style={{ textDecoration: "none" }}>
                      <li
                        className="menu-items"
                        
                      >
                        {" "}
                        Services
                      </li>
                    </Link>
                    <Link to="/company" style={{ textDecoration: "none" }}>
                      <li
                        className="menu-items"
                      
                      >
                        Company
                      </li>
                    </Link>

                    <Link to="/contacts" style={{ textDecoration: "none" }}>
                      {" "}
                      <li
                        style={{
                          paddingBottom: "25px",
                          color: "var(--mateblack)",
                        }}
                      >
                        Contacts
                      </li>
                    </Link>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "80%",
            margin: "0 auto",
            position: "absolute",
            bottom: "30px",
            left: "80px",
            "@media (max-width: 600px)": {
              left: "20px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              color: "var(--tertiary-color)",
              "@media (max-width: 1440px)": {
                fontSize: "16px",
              },
            }}
          >
            Get In Touch
          </Typography>{" "}
          <Box
            sx={{
              paddingRight: "20px",
              borderBottom: "2px solid var(--tertiary-color)",
              width: "45%",
              display: "flex",
              gap: "40px",
              paddingTop: "10px",
              paddingBottom: "5px",
              fontSize: "24px",
              "@media (max-width: 1440px)": {
                fontSize: "17px",
                width: "40%",
              },
              "@media (max-width: 1024px)": {
                fontSize: "17px",
                width: "70%",
              },
              "@media (max-width: 768px)": {
                fontSize: "17px",
                width: "37%",
              },
              "@media (max-width: 428px)": {
                fontSize: "17px",
                width: "65%",
              },
              "@media (max-width: 320px)": {
                fontSize: "17px",
                width: "90%",
              },
            }}
          >
            <span>Info@flyfartech.com</span>{" "}
            <ContentCopyIcon
              onClick={handleCopyClick}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "var(--primary-color)",
                },
              }}
            />
          </Box>{" "}
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideBar;
