import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
// import service1 from "../../v2images/service1.png";
// import service2 from "../../v2images/service2.png";
// import service3 from "../../v2images/service3.png";
// import service4 from "../../v2images/service4.png";
// import service5 from "../../v2images/service5.png";
import service1 from "../../v2images/techweb/Travel-Portal-Development.png";
import service2 from "../../v2images/techweb/Travel-Technology-Solution.png";
import service3 from "../../v2images/techweb/Api.png";
import service4 from "../../v2images/techweb/Mobile-Application.png";
import service5 from "../../v2images/techweb/Hotel-Booking-Engine.png";
import service6 from "../../v2images/techweb/Flight-Bookin-Software.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const Services = () => {
  const [cardId, setCardId] = useState(0);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          arrows: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          arrows: true,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <Box sx={{ width: "70%", margin: "10px auto" }}>
      <Box sx={{ pt: "200px" }}>
        <Box
          sx={{
            textAlign: {
              xs: "center",
              sm: "center",
              md: "start",
              lg: "start",
            },
          }}
        >
          <Typography
            sx={{
              color: "var(--mateblack)",
              fontWeight: "400",
              fontSize: "50px",
            }}
          >
            our service
          </Typography>
          <Typography
            sx={{
              color: "var(--primary-color)",
              fontWeight: "400",
              fontSize: {
                xs: "30px",
                sm: "30px",
                md: "40px",
                lg: "50px",
              },
            }}
          >
            we do everything !!
          </Typography>
        </Box>
        <Box sx={{ pt: "70px", pb: "30px" }}>
          <Box
            sx={{
              ".slick-slide > div": {
                margin: "1px 10px",
                width: "auto",
              },
              ".slick-list": {
                margin: "0px 0px",
              },
            }}
          >
            <Slider {...settings}>
              {/* service card 1  */}
              <Box
                className="container"
                sx={{
                  background: "#FFFFFFF",
                  textAlign: "center",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",

                  "@media (max-width: 1440px)": {
                    padding: "8px 18px",
                    height: "350px",
                  },
                  "@media (max-width: 1024px)": {
                    padding: "8px 18px",
                    height: "320px",
                  },
                  "@media (max-width: 768px)": {
                    padding: "8px 18px",
                    height: "360px",
                  },
                  "@media (max-width: 428px)": {
                    padding: "10px 20px",
                    height: "360px",
                  },
                  "@media (max-width: 320px)": {
                    padding: "10px 25px",
                    height: "350px",
                  },
                  borderTop: "10px solid var(--primary-color)",

                  padding: "10px 25px",
                  height: "350px",
                  position: "relative",
                  zIndex: "1000",

                  ":hover": {
                    "& button": {
                      bgcolor: "var(--white)",
                      color: "var(--mateblack)",
                    },
                    "& .title": {
                      color: "var(--white)",
                    },
                    "& .textBody": {
                      color: "var(--white)",
                    },
                  },
                }}
              >
                <Typography
                  className="title"
                  sx={{
                    fontSize: "18px",

                    "@media (max-width: 1440px)": {
                      fontSize: "18px",
                    },
                    "@media (max-width: 1024px)": {
                      fontSize: "15px",
                    },
                    "@media (max-width: 768px)": {
                      fontSize: "18px",
                    },
                    "@media (max-width: 428px)": {
                      fontSize: "20px",
                    },
                    "@media (max-width: 320px)": {
                      fontSize: "16px",
                    },
                    paddingTop: "10px",
                    color: "var(--primary-color)",
                    zIndex: "99",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  OTA Management
                </Typography>
                <Typography
                  className="textBody"
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    color: "#9A9A9D",
                    mt: "20px",
                    height: "200px",
                    position: "relative",
                    zIndex: "99",
                    "@media (max-width: 1440px)": {
                      fontSize: "15px",
                      height: "240px",
                    },
                    "@media (max-width: 1024px)": {
                      fontSize: "13px",
                      height: "200px",
                    },
                    "@media (max-width: 768px)": {
                      fontSize: "15px",
                      height: "240px",
                    },
                    "@media (max-width: 428px)": {
                      fontSize: "16px",
                      height: "240px",
                    },
                    "@media (max-width: 320px)": {
                      fontSize: "13px",
                      height: "220px",
                    },
                  }}
                >
                  OTA Management oversees online platforms for booking travel
                  services like flights, hotels, and tours. It optimizes
                  platform performance for smooth experiences for customers and
                  agents.
                </Typography>

                <Button
                  sx={{
                    bgcolor: "var(--secondary-color)",

                    color: "var(--white)",
                    fontSize: "14px",
                    padding: "8px 20px 10px 20px",
                    textTransform: "none",
                    borderRadius: "50px",
                    zIndex: "99",
                    position: "relative",
                    transition: "background-color 1.5s",
                    ":hover": {
                      bgcolor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "@media (max-width: 1440px)": {
                      fontSize: "15px",
                      width: "170px",
                    },
                  }}
                >
                  explore more
                </Button>

                <Box
                  className={cardId % 2 !== 0 ? "overlay-2" : "overlay"}
                ></Box>
              </Box>
              {/* service card 2 */}
              <Box
                className="container"
                sx={{
                  background: "#FFFFFFF",
                  textAlign: "center",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",

                  "@media (max-width: 1440px)": {
                    padding: "8px 18px",
                    height: "350px",
                  },
                  "@media (max-width: 1024px)": {
                    padding: "8px 18px",
                    height: "320px",
                  },
                  "@media (max-width: 768px)": {
                    padding: "8px 18px",
                    height: "360px",
                  },
                  "@media (max-width: 428px)": {
                    padding: "10px 20px",
                    height: "360px",
                  },
                  "@media (max-width: 320px)": {
                    padding: "10px 25px",
                    height: "350px",
                  },
                  borderTop: "10px solid var(--primary-color)",

                  padding: "10px 25px",
                  height: "350px",
                  position: "relative",
                  zIndex: "1000",

                  ":hover": {
                    "& button": {
                      bgcolor: "var(--white)",
                      color: "var(--mateblack)",
                    },
                    "& .title": {
                      color: "var(--white)",
                    },
                    "& .textBody": {
                      color: "var(--white)",
                    },
                  },
                }}
              >
                <Typography
                  className="title"
                  sx={{
                    fontSize: "18px",

                    "@media (max-width: 1440px)": {
                      fontSize: "18px",
                    },
                    "@media (max-width: 1024px)": {
                      fontSize: "15px",
                    },
                    "@media (max-width: 768px)": {
                      fontSize: "18px",
                    },
                    "@media (max-width: 428px)": {
                      fontSize: "20px",
                    },
                    "@media (max-width: 320px)": {
                      fontSize: "16px",
                    },
                    paddingTop: "10px",
                    color: "var(--primary-color)",
                    zIndex: "99",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  Travels Solutions
                </Typography>
                <Typography
                  className="textBody"
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    color: "#9A9A9D",
                    mt: "20px",
                    height: "200px",
                    position: "relative",
                    zIndex: "99",
                    "@media (max-width: 1440px)": {
                      fontSize: "15px",
                      height: "240px",
                    },
                    "@media (max-width: 1024px)": {
                      fontSize: "13px",
                      height: "200px",
                    },
                    "@media (max-width: 768px)": {
                      fontSize: "15px",
                      height: "240px",
                    },
                    "@media (max-width: 428px)": {
                      fontSize: "16px",
                      height: "240px",
                    },
                    "@media (max-width: 320px)": {
                      fontSize: "13px",
                      height: "220px",
                    },
                  }}
                >
                  Travel solutions streamline booking, itinerary management, and
                  expense tracking to enhance travel experiences for businesses
                  and individuals.
                </Typography>

                <Button
                  sx={{
                    bgcolor: "var(--secondary-color)",
                    color: "var(--white)",
                    fontSize: "14px",
                    padding: "8px 20px 10px 20px",
                    textTransform: "none",
                    borderRadius: "50px",
                    zIndex: "99",
                    position: "relative",
                    transition: "background-color 1.5s",
                    ":hover": {
                      bgcolor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "@media (max-width: 1440px)": {
                      fontSize: "15px",
                      width: "170px",
                    },
                  }}
                >
                  explore more
                </Button>

                <Box
                  className={cardId % 2 !== 0 ? "overlay-2" : "overlay"}
                ></Box>
              </Box>

              {/* Service card 3  */}

              <Box
                className="container"
                sx={{
                  background: "#FFFFFFF",
                  textAlign: "center",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",

                  "@media (max-width: 1440px)": {
                    padding: "8px 18px",
                    height: "350px",
                  },
                  "@media (max-width: 1024px)": {
                    padding: "8px 18px",
                    height: "320px",
                  },
                  "@media (max-width: 768px)": {
                    padding: "8px 18px",
                    height: "360px",
                  },
                  "@media (max-width: 428px)": {
                    padding: "10px 20px",
                    height: "360px",
                  },
                  "@media (max-width: 320px)": {
                    padding: "10px 25px",
                    height: "350px",
                  },
                  borderTop: "10px solid var(--primary-color)",

                  padding: "10px 25px",
                  height: "350px",
                  position: "relative",
                  zIndex: "1000",

                  ":hover": {
                    "& button": {
                      bgcolor: "var(--white)",
                      color: "var(--mateblack)",
                    },
                    "& .title": {
                      color: "var(--white)",
                    },
                    "& .textBody": {
                      color: "var(--white)",
                    },
                  },
                }}
              >
                <Typography
                  className="title"
                  sx={{
                    fontSize: "18px",

                    "@media (max-width: 1440px)": {
                      fontSize: "18px",
                    },
                    "@media (max-width: 1024px)": {
                      fontSize: "15px",
                    },
                    "@media (max-width: 768px)": {
                      fontSize: "18px",
                    },
                    "@media (max-width: 428px)": {
                      fontSize: "20px",
                    },
                    "@media (max-width: 320px)": {
                      fontSize: "16px",
                    },
                    paddingTop: "10px",
                    color: "var(--primary-color)",
                    zIndex: "99",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  Flight API
                </Typography>
                <Typography
                  className="textBody"
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    color: "#9A9A9D",
                    mt: "20px",
                    height: "200px",
                    position: "relative",
                    zIndex: "99",
                    "@media (max-width: 1440px)": {
                      fontSize: "15px",
                      height: "240px",
                    },
                    "@media (max-width: 1024px)": {
                      fontSize: "13px",
                      height: "200px",
                    },
                    "@media (max-width: 768px)": {
                      fontSize: "15px",
                      height: "240px",
                    },
                    "@media (max-width: 428px)": {
                      fontSize: "16px",
                      height: "240px",
                    },
                    "@media (max-width: 320px)": {
                      fontSize: "13px",
                      height: "220px",
                    },
                  }}
                >
                  Flight APIs enable access to real-time flight data, pricing,
                  and booking functionality, empowering travel platforms to
                  offer seamless flight search and booking experiences to users.
                </Typography>

                <Button
                  sx={{
                    bgcolor: "var(--secondary-color)",

                    color: "var(--white)",
                    fontSize: "14px",
                    padding: "8px 20px 10px 20px",
                    textTransform: "none",
                    borderRadius: "50px",
                    zIndex: "99",
                    position: "relative",
                    transition: "background-color 1.5s",
                    ":hover": {
                      bgcolor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "@media (max-width: 1440px)": {
                      fontSize: "15px",
                      width: "170px",
                    },
                  }}
                >
                  explore more
                </Button>

                <Box
                  className={cardId % 2 !== 0 ? "overlay-2" : "overlay"}
                ></Box>
              </Box>

              {/* Service card 4 */}
              <Box
                className="container"
                sx={{
                  background: "#FFFFFFF",
                  textAlign: "center",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",

                  "@media (max-width: 1440px)": {
                    padding: "8px 18px",
                    height: "350px",
                  },
                  "@media (max-width: 1024px)": {
                    padding: "8px 18px",
                    height: "320px",
                  },
                  "@media (max-width: 768px)": {
                    padding: "8px 18px",
                    height: "360px",
                  },
                  "@media (max-width: 428px)": {
                    padding: "10px 20px",
                    height: "360px",
                  },
                  "@media (max-width: 320px)": {
                    padding: "10px 25px",
                    height: "350px",
                  },
                  borderTop: "10px solid var(--primary-color)",

                  padding: "10px 25px",
                  height: "350px",
                  position: "relative",
                  zIndex: "1000",

                  ":hover": {
                    "& button": {
                      bgcolor: "var(--white)",
                      color: "var(--mateblack)",
                    },
                    "& .title": {
                      color: "var(--white)",
                    },
                    "& .textBody": {
                      color: "var(--white)",
                    },
                  },
                }}
              >
                <Typography
                  className="title"
                  sx={{
                    fontSize: "18px",

                    "@media (max-width: 1440px)": {
                      fontSize: "18px",
                    },
                    "@media (max-width: 1024px)": {
                      fontSize: "15px",
                    },
                    "@media (max-width: 768px)": {
                      fontSize: "18px",
                    },
                    "@media (max-width: 428px)": {
                      fontSize: "20px",
                    },
                    "@media (max-width: 320px)": {
                      fontSize: "16px",
                    },
                    paddingTop: "10px",
                    color: "var(--primary-color)",
                    zIndex: "99",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  HRM Solutions
                </Typography>
                <Typography
                  className="textBody"
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    color: "#9A9A9D",
                    mt: "20px",
                    height: "200px",
                    position: "relative",
                    zIndex: "99",
                    "@media (max-width: 1440px)": {
                      fontSize: "15px",
                      height: "240px",
                    },
                    "@media (max-width: 1024px)": {
                      fontSize: "13px",
                      height: "200px",
                    },
                    "@media (max-width: 768px)": {
                      fontSize: "15px",
                      height: "240px",
                    },
                    "@media (max-width: 428px)": {
                      fontSize: "16px",
                      height: "240px",
                    },
                    "@media (max-width: 320px)": {
                      fontSize: "13px",
                      height: "220px",
                    },
                  }}
                >
                  HRM Solutions automate HR tasks, manage employee data, track
                  performance, facilitate recruitment, administer payroll, and
                  ensure regulatory compliance within organizations.
                </Typography>

                <Button
                  sx={{
                    bgcolor: "var(--secondary-color)",

                    color: "var(--white)",
                    fontSize: "14px",
                    padding: "8px 20px 10px 20px",
                    textTransform: "none",
                    borderRadius: "50px",
                    zIndex: "99",
                    position: "relative",
                    transition: "background-color 1.5s",
                    ":hover": {
                      bgcolor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "@media (max-width: 1440px)": {
                      fontSize: "15px",
                      width: "170px",
                    },
                  }}
                >
                  explore more
                </Button>

                <Box
                  className={cardId % 2 !== 0 ? "overlay-2" : "overlay"}
                ></Box>
              </Box>

              {/* Service card 5 */}
              <Box
                className="container"
                sx={{
                  background: "#FFFFFFF",
                  textAlign: "center",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",

                  "@media (max-width: 1440px)": {
                    padding: "8px 18px",
                    height: "350px",
                  },
                  "@media (max-width: 1024px)": {
                    padding: "8px 18px",
                    height: "320px",
                  },
                  "@media (max-width: 768px)": {
                    padding: "8px 18px",
                    height: "360px",
                  },
                  "@media (max-width: 428px)": {
                    padding: "10px 20px",
                    height: "360px",
                  },
                  "@media (max-width: 320px)": {
                    padding: "10px 25px",
                    height: "350px",
                  },
                  borderTop: "10px solid var(--primary-color)",

                  padding: "10px 25px",
                  height: "350px",
                  position: "relative",
                  zIndex: "1000",

                  ":hover": {
                    "& button": {
                      bgcolor: "var(--white)",
                      color: "var(--mateblack)",
                    },
                    "& .title": {
                      color: "var(--white)",
                    },
                    "& .textBody": {
                      color: "var(--white)",
                    },
                  },
                }}
              >
                <Typography
                  className="title"
                  sx={{
                    fontSize: "18px",

                    "@media (max-width: 1440px)": {
                      fontSize: "18px",
                    },
                    "@media (max-width: 1024px)": {
                      fontSize: "15px",
                    },
                    "@media (max-width: 768px)": {
                      fontSize: "18px",
                    },
                    "@media (max-width: 428px)": {
                      fontSize: "20px",
                    },
                    "@media (max-width: 320px)": {
                      fontSize: "16px",
                    },
                    paddingTop: "10px",
                    color: "var(--primary-color)",
                    zIndex: "99",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  ERP Solutions
                </Typography>
                <Typography
                  className="textBody"
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    color: "#9A9A9D",
                    mt: "20px",
                    height: "200px",
                    position: "relative",
                    zIndex: "99",
                    "@media (max-width: 1440px)": {
                      fontSize: "15px",
                      height: "240px",
                    },
                    "@media (max-width: 1024px)": {
                      fontSize: "13px",
                      height: "200px",
                    },
                    "@media (max-width: 768px)": {
                      fontSize: "15px",
                      height: "240px",
                    },
                    "@media (max-width: 428px)": {
                      fontSize: "16px",
                      height: "240px",
                    },
                    "@media (max-width: 320px)": {
                      fontSize: "13px",
                      height: "220px",
                    },
                  }}
                >
                  ERP Solutions integrate and streamline business processes
                  across departments, including finance, HR, supply chain, CRM,
                  manufacturing, inventory, and project management, to improve
                  efficiency and decision-making within organizations.
                </Typography>

                <Button
                  sx={{
                    bgcolor: "var(--secondary-color)",

                    color: "var(--white)",
                    fontSize: "14px",
                    padding: "8px 20px 10px 20px",
                    textTransform: "none",
                    borderRadius: "50px",
                    zIndex: "99",
                    position: "relative",
                    transition: "background-color 1.5s",
                    ":hover": {
                      bgcolor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "@media (max-width: 1440px)": {
                      fontSize: "15px",
                      width: "170px",
                    },
                  }}
                >
                  explore more
                </Button>

                <Box
                  className={cardId % 2 !== 0 ? "overlay-2" : "overlay"}
                ></Box>
              </Box>
            </Slider>

            {/* <Link to="/services" className="service-button">
              <Button
                sx={{
                  color: "var(--primary-color)",
                  fontSize: "15px",
                  bgcolor: "transparent",
                  textTransform: "none",
                  height: "40px",
                  borderRadius: "50px",
                  border: "1.5px solid var(--primary-color)",
                  // : "1.5px solid #FFFFFF",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginBottom: "20px",
                  marginTop: "50px",
                  ":hover": {
                    backgroundColor: "var(--secondary-color)",
                    color: "var(--mateblack)",
                    border: "1px solid var(--secondary-color)",
                  },
                }}
              >
                view all services
              </Button>
            </Link> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Services;
