import { Box, ClickAwayListener } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavBar from "./NavBar/NavBar";
import Banner from "./Banner/Banner";
import Title from "./Title/Title";
import Services from "../Services/Services";
import WorkProcess from "../WorkProcess/WorkProcess";
import ChooseUs from "../ChooseUs/ChooseUs";
import Project from "../Project/Project";
import Footer from "../Footer/Footer";
import SideBar from "../../../components/SideBar/SideBar";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import useMousePosition from "../../../hooks/useMousePosition";
import getCursorProps from "../../../getCursorProps";
import Products from "../Products/Products";
import BestProduct from "../../../components/BestProducts/BestProduct";
import Contacts from "../../../components/Contacts/Contacts";

const Home = () => {
  const [bannerElements, setBannerElements] = useState({});
  const mousePosition = useMousePosition();
  const [cursorProps, setCursorProps] = useState({
    videoID: null,
    scale: 1,
  });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState("");
  const [mode, setMode] = useState("");
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("1024"));

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--body-background-color",
      mode ? "white" : "#222222"
    );
  }, [mode]);

  const handleMode = () => {
    setMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("mode", newMode ? "true" : "false");
      return newMode;
    });
  };

  useEffect(() => {
    const storedMode = localStorage.getItem("mode");
    if (storedMode !== null) {
      setMode(storedMode === "true");
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setCursorProps(getCursorProps(bannerElements, mousePosition));
  }, [bannerElements, mousePosition]);
  return (
    <Box>
      <SideBar
        open={open}
        setOpen={setOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleMode={handleMode}
        mode={mode}
      />
      <Banner />
      <Products />

      <Services />
      <ChooseUs />
      <Footer/>
    </Box>
  );
};

export default Home;
