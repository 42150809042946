import { Box, Typography } from "@mui/material";
import React from "react";
import { Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { Fade } from "react-reveal";

const Product = ({ selectedItem }) => {
  console.log("selected", selectedItem);
  return (
    <Box
      sx={{
        width: "100%",
        height: "330px",
        "@media (max-width: 1024px)": {
          height: "260px",
          pl: "30px",
          pr: "50px",
        },
        bgcolor: "var(--cards-bg)",
        borderRadius: "0px 10px 10px ",
        boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
        pl: "40px",
        pr: "60px",
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      {selectedItem === 0 && (
        <Box sx={{ width: "70%", pt: "25px" }}>
          <Typography
            sx={{
              fontSize: "23px",
              color: "var(--primary-color)",
              fontWeight: 500,
            }}
          >
            Whitelable OTA{" "}
          </Typography>
          <Typography
            sx={{
              color: "#666676",
              fontSize: "15px",
              pt: "15px",
              "@media (max-width: 1440px)": {
                pt: "14px",
                fontSize: "14px",
              },
              "@media (max-width: 1024px)": {
                pt: "10px",
                fontSize: "12px",
              },
              "@media (max-width: 320px)": {
                pt: "10px",
                fontSize: "12px",
              },
            }}
          >
            Discover the top OTA in Bangladesh for unbeatable prices and
            comprehensive services. Our White Label solution offers B2B/B2C
            portals, mobile app support, 24/7 tech assistance, and access to
            700+ airlines. Enjoy generous commissions, group fares, visa
            modules, and essential tools like Hotel and PNR share. Seamlessly
            manage your business with unlimited sub-agents, partial payments,
            and regular upgrades. Elevate your business effortlessly with our
            OTA portal.
          </Typography>
          <Box>
            <Button
              sx={{
                color: "var(--mateblack)",
                fontSize: "12px",
                bgcolor: "transparent",
                textTransform: "none",
                borderRadius: "50px",
                border: "2px solid var(--mateblack)",
                fontWeight: "600",
                mt: "30px",
                padding: "5px 15px 7px 18px",
                marginRight: "10px",
              }}
            >
              {" "}
              ask a question
            </Button>
            <Button
              sx={{
                color: "var(--white)",
                fontSize: "12px",
                bgcolor: "transparent",
                textTransform: "none",
                borderRadius: "50px",
                //   border: "1.5px solid var(--mateblack)",
                bgcolor: "var(--primary-color)",
                fontWeight: "600",
                mt: "30px",
                padding: "5px 15px 7px 18px",
              }}
            >
              {" "}
              live demo
            </Button>
          </Box>
        </Box>
      )}
      {selectedItem === 1 && (
        <Box sx={{ width: "70%", pt: "25px" }}>
          <Typography
            sx={{
              fontSize: "23px",
              color: "var(--primary-color)",
              fontWeight: 500,
            }}
          >
            Flight API
          </Typography>
          <Typography
            sx={{
              color: "#666676",
              fontSize: "15px",
              pt: "15px",
              "@media (max-width: 1440px)": {
                pt: "14px",
                fontSize: "14px",
              },
              "@media (max-width: 1024px)": {
                pt: "10px",
                fontSize: "12px",
              },
              "@media (max-width: 320px)": {
                pt: "10px",
                fontSize: "12px",
              },
            }}
          >
            We currently operate within the same Passenger Control Centre (PCC),
            enabling searches for airline tickets across economy and business
            classes, as well as manual booking, ticket issuance, reissuing,
            refunds, and voids. Moving forward, we're dedicated to enhancing our
            services by introducing automatic ticket issuance, refunds, and
            voids. This strategic upgrade aims to streamline operations,
            ensuring greater efficiency and convenience. By integrating these
            automated processes, we'll elevate the overall experience, making
            transactions smoother for all customers.
          </Typography>
          <Box>
            <Button
              sx={{
                color: "var(--mateblack)",
                fontSize: "12px",
                bgcolor: "transparent",
                textTransform: "none",
                borderRadius: "50px",
                border: "2px solid var(--mateblack)",
                fontWeight: "600",
                mt: "30px",
                padding: "5px 15px 7px 18px",
                marginRight: "10px",
              }}
            >
              {" "}
              ask a question
            </Button>
            <Button
              sx={{
                color: "var(--white)",
                fontSize: "12px",
                bgcolor: "transparent",
                textTransform: "none",
                borderRadius: "50px",
                //   border: "1.5px solid var(--mateblack)",
                bgcolor: "var(--primary-color)",
                fontWeight: "600",
                mt: "30px",
                padding: "5px 15px 7px 18px",
              }}
            >
              {" "}
              live demo
            </Button>
          </Box>
        </Box>
      )}
      {selectedItem === 2 && (
        <Box sx={{ width: "70%", pt: "25px" }}>
          <Typography
            sx={{
              fontSize: "23px",
              color: "var(--primary-color)",
              fontWeight: 500,
            }}
          >
           HRM Pro
          </Typography>
          <Typography
            sx={{
              color: "#666676",
              fontSize: "15px",
              pt: "15px",
              "@media (max-width: 1440px)": {
                pt: "14px",
                fontSize: "14px",
              },
              "@media (max-width: 1024px)": {
                pt: "10px",
                fontSize: "12px",
              },
              "@media (max-width: 320px)": {
                pt: "10px",
                fontSize: "12px",
              },
            }}
          >
            HRM Pro stands out as the premier HR & Payroll Software in
            Bangladesh, streamlining HR processes for both employees and
            managers. It's a powerful tool for ensuring accurate record-keeping,
            leading to reliable performance reports. As a compact package of web
            and mobile apps, HRM Pro offers a wealth of features at affordable
            prices, catering to small and large companies alike. Experience
            enhanced HR management with HRM Pro.
          </Typography>
          <Box>
            <Button
              sx={{
                color: "var(--mateblack)",
                fontSize: "12px",
                bgcolor: "transparent",
                textTransform: "none",
                borderRadius: "50px",
                border: "2px solid var(--mateblack)",
                fontWeight: "600",
                mt: "30px",
                padding: "5px 15px 7px 18px",
                marginRight: "10px",
              }}
            >
              {" "}
              ask a question
            </Button>
            <Button
              sx={{
                color: "var(--white)",
                fontSize: "12px",
                bgcolor: "transparent",
                textTransform: "none",
                borderRadius: "50px",
                //   border: "1.5px solid var(--mateblack)",
                bgcolor: "var(--primary-color)",
                fontWeight: "600",
                mt: "30px",
                padding: "5px 15px 7px 18px",
              }}
            >
              {" "}
              live demo
            </Button>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          width: "25%",
          bgcolor: "var(--white)",
          border: "3px solid var(--secondary-color)",
          borderRadius: "15px",
          height: "400px",
          "@media (max-width: 1024px)": {
            height: "320px",
            pl: "0px",
            pr: "0px",
          },
          position: "absolute",
          right: "40px",
          bottom: "-30px",
        }}
      >
        <Box
          sx={{
            padding: "30px",
            "@media (max-width: 1024px)": {
              padding: "20px",
            },
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "15px",
                color: "var(--mateblack)",
                "@media (max-width: 1024px)": {
                  fontSize: "12px",
                },
              }}
            >
              BDT
            </Typography>

            <Box sx={{ display: "flex", gap: "14px" }}>
              <Typography
                sx={{
                  fontSize: "50px",
                  color: "var(--mateblack)",

                  "@media (max-width: 1024px)": {
                    fontSize: "30px",
                  },
                }}
              >
                99,999{" "}
              </Typography>
              <Typography
                sx={{
                  fontSize: "17px",
                  color: "#4F76AE",
                  position: "relative",
                  marginTop: "auto",
                  marginBottom: "15px",
                  "@media (max-width: 1024px)": {
                    fontSize: "14px",
                    position: "relative",
                    marginTop: "auto",
                    marginBottom: "7px",
                  },
                }}
              >
                1,00,000
              </Typography>
            </Box>

            <Box
              sx={{
                border: "1px solid var(--primary-color)",
                position: "absolute",
                top: "100px",
                left: "200px",
                width: "72px",
                transform: "rotate(-11deg)",
                "@media (max-width: 1024px)": {
                  top: "67px",
                  left: "130px",
                  width: "60px",
                  transform: "rotate(-12deg)",
                },
              }}
            ></Box>
          </Box>

          <Box sx={{ mt: "15px" }}>
            <ul style={{ fontSize: "13px" }}>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "#666676",
                }}
              >
                {" "}
                <DoneIcon sx={{ fontSize: "14px" }} /> custom domain{" "}
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "#666676",
                }}
              >
                <DoneIcon sx={{ fontSize: "14px" }} />1 years free hosting{" "}
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "#666676",
                }}
              >
                <DoneIcon sx={{ fontSize: "14px" }} />
                unlimited storage{" "}
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "#666676",
                }}
              >
                <DoneIcon sx={{ fontSize: "14px" }} />
                include b2b, b2c and mobile app{" "}
              </li>
            </ul>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              paddingTop: "60px",
              "@media (max-width: 1024px)": {
                paddingTop: "30px",
              },
            }}
          >
            <Button sx={{ textTransform: "none", paddingLeft: "0px" }}>
              Request for Demo
            </Button>
            <Button
              sx={{
                textTransform: "none",
                bgcolor: "var(--secondary-color)",
                color: "var(--black)",
                ":hover": {
                  bgcolor: "var(--secondary-color)",
                  color: "var(--black)",
                },
                width: "100%",
                textTransform: "capitalize",
                fontWeight: "500",
                fontSize: "16px",
                mt: "10px",
              }}
            >
              book yours
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Product;
